import { Outlet, ScrollRestoration } from "react-router-dom";

function Root() {

  return (
    <>
      <div className="page-wrapper">
        <Outlet />
        <ScrollRestoration />
      </div>
    </>
  );
}

export default Root;
