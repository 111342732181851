import {lazy} from 'react'
import { createBrowserRouter } from "react-router-dom";
import Root from "../pages/Root";

const Home = lazy(()=> import ('../pages/Homepage'))
const Work = lazy(()=> import ('../pages/Work'))

const routes = [
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/work",
        element: <Work />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);
export default router;